<template>
  <div class="content" ref="content">
    <div class="main-content" style="background: #f8fbfc">
      <div class="top-info" ref="top">
        <a-row :gutter="20">
          <a-col>
            <div class="info-box box-1">
              <div class="info-image">
                <icon-font style="font-size: 0.4rem" type="icon-dingdan1" />
              </div>
              <div class="info">
                <div class="info-title">今日订单</div>
                <div class="info-value">
                  {{ stat.orderCount }}
                </div>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="info-box box-2">
              <div class="info-image">
                <icon-font style="font-size: 0.4rem" type="icon-liushui" />
              </div>
              <div class="info">
                <div class="info-title">今日流水</div>
                <div class="info-value">{{ stat.orderMoney }}</div>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="info-box box-3">
              <div class="info-image">
                <icon-font style="font-size: 0.4rem" type="icon-keliuliang" />
              </div>
              <div class="info">
                <div class="info-title">今日客流量</div>
                <div class="info-value">{{ stat.peopleCount }}</div>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="info-box box-4">
              <div class="info-image">
                <icon-font
                  style="font-size: 0.4rem"
                  type="icon--shangzuoshuai-"
                />
              </div>
              <div class="info">
                <div class="info-title">上座率</div>
                <div class="info-value">{{ stat.attendanceRate }}</div>
              </div>
            </div>
          </a-col>
          <a-col>
            <div class="info-box box-4">
              <div class="info-image">
                <icon-font
                  style="font-size: 0.4rem"
                  type="icon-liushui"
                />
              </div>
              <div class="info">
                <div class="info-title">优惠金额</div>
                <div class="info-value">{{ stat.saveAmount }}</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>

      <div class="line-content">
        <div class="line-box">
          <div class="line-title">每日流水统计</div>
          <div class="line">
            <ELine
              ref="eLine"
              :loading="loading"
              :source="source"
              :styles="'width:100%;height:' + height + 'px'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import ELine from '@/components/echarts/line'
import { StatClass } from '@/apis/stat'

const api = new StatClass()
export default defineComponent({
  components: {
    ELine
  },
  setup () {
    const loading = ref(false)
    const source = ref([])
    const height = ref(300)
    const stat = ref({
      count: 0,
      money: 0,
      canteenCount: 0
    })

    const onGetStat = () => {
      api.info().then((resp) => {
        stat.value = resp
      })
    }

    const onGetLineDatas = () => {
      loading.value = true
      api.line().then((resp) => {
        source.value = resp
        loading.value = false
      })
    }

    onGetStat()
    onGetLineDatas()

    return {
      loading,
      stat,
      source,
      height
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.getHeight()
    })
  },

  methods: {
    getHeight () {
      const mainPadding = 30
      const thead = 56
      const height =
        this.$refs.content.offsetHeight -
        this.$refs.top.offsetHeight -
        thead -
        90 -
        mainPadding

      this.height = height
    }
  }
})
</script>

<style lang="less" scoped>
.top-info {
  .info-box {
    display: flex;
    padding: 20px 40px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    .info-image {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .info {
      padding-left: 30px;
      padding-top: 9px;
      .info-title {
        font-size: 18px;
        color: #666666;
        line-height: 35px;
      }
      .info-value {
        color: #333333;
        font-size: 40px;
        line-height: 45px;
        span {
          font-size: 14px;
          color: #999999;
        }
      }
    }

    &.box-1 {
      .info-image {
        background: #ceffa6;
      }
    }
    &.box-2 {
      .info-image {
        background: #ffb2c9;
      }
    }
    &.box-3 {
      .info-image {
        background: #ffd890;
      }
    }
    &.box-4 {
      .info-image {
        background: #e590ff;
      }
    }
  }
}

.line-content {
  padding-top: 30px;
  .line-box {
    padding: 20px 40px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    .line-title {
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #333333;
      &:before {
        content: "";
        display: block;
        height: 23px;
        border-left: 3px solid #185ef9;
        width: 26px;
      }
    }

    .line {
    }
  }
}
</style>
